import React from 'react'
import "./FAQ.scss";

export default function FAQ() {
  return (
    <div className='container'>
        <div id="faq" className='section'>
            <div className='text-center'>
               <span className='section-tag-title'>FAQ</span>
               <h2 className='section-description txtMain'>Frequently Asked Questions</h2>
            </div>  
            < div class="accordion accordion-flush custom-accordion" id="accordionExample" >
                    <div className='accordion-container'>
                        <div className="accordion-item" >
                            <h2 className="accordion-header" id="headingOne" >
                                <button className="accordion-button collapsed faqQuestion" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ zIndex: "0", border: "0px" }}  >
                                    Are you authorized representative?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse faqAnswer" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body pt-4">
                                    Faisal Nour is a licensed Immigration Canadian Consultant RCIC, a registered and licensed member in good standing of the College of Immigration and Citizenship Consultants. It is important to note that only authorized representatives are allowed to represent individuals in an immigration or citizenship application as well as communicate with the Government of Canada on their behalf.
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* second item */}
                    <div className="accordion-container" >
                        <div className="accordion-item" >
                            <h2 className="accordion-header" id="headingTwo" >
                                <button className="accordion-button collapsed faqQuestion" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{ zIndex: "0", border: "0px" }}  >
                                    What services do you offer?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse faqAnswer" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body pt-4">
                                    We offer various immigration services for students, experienced professionals, entrepreneurs, individuals who seek to immigrate to Canada, permanent residents, and Canadian citizens who would like to sponsor family members and permanent residents who want to obtain Canadian citizenship.
                                </div>
                            </div>
                        </div>
                    </div>
                        {/* third item */}
                    <div className="accordion-container" >
                        <div className="accordion-item" >
                            <h2 className="accordion-header" id="headingThree" >
                                <button className="accordion-button collapsed faqQuestion" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" style={{ zIndex: "0", border: "0px" }}  >
                                    Do you currently offer In-Office appointments?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse faqAnswer" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body pt-4">
                                    We prioritize the convenience of our clients by leveraging technology to offer telephone and virtual meetings, allowing connection with our team in a seamless and secure manner from anywhere. In some cases, we offer in-person meetings by appointment.
                                </div>
                            </div>
                        </div>
                    </div>
                        {/* fourth item */}
                    <div className="accordion-container" >
                        <div className="accordion-item" >
                            <h2 className="accordion-header" id="headingFour" >
                                <button className="accordion-button collapsed faqQuestion" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{ zIndex: "0", border: "0px" }}  >
                                    How do I get started?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse faqAnswer" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body pt-4">
                                Booking a 1-hour consultation meeting allows us to initially review the overall information provided to determine how to best move forward with your application.
                                Following this, we'll provide a retainer agreement, clearly outlining our services, processes, and payment details. Our services commence upon receipt of the completed payment.
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* fifth item */}
                    <div className="accordion-container" >
                        <div className="accordion-item" >
                            <h2 className="accordion-header" id="headingFive" >
                                <button className="accordion-button collapsed faqQuestion" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{ zIndex: "0", border: "0px" }}  >
                                    Do I need to pay the amount in full or do you offer payment instalments?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse faqAnswer" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body pt-4">
                                    RedRadius Immigration Consultancy operates through a trust account in accordance with the ICCRC Client Account Regulation. If applying from inside Canada, payment can be made through an Interac e-Transfer, a wire transfer, or a cheque. If applying from outside of Canada, payment can only be made through an international wire transfer. All payments must be paid in full to start our services.
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* sixth item */}
                    <div className="accordion-container" >
                        <div className="accordion-item" >
                            <h2 className="accordion-header" id="headingSix" >
                                <button className="accordion-button collapsed faqQuestion" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix" style={{ zIndex: "0", border: "0px" }}  >
                                    How long does the application process take?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse faqAnswer" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body pt-4">
                                    It typically takes 1- week to prepare the application for submission once all the documents and information required are received from the client. The government processing time varies depending on the type of application, country of residence, the application processing office, and additional requirements that may arise during the process. For more details, please visit the RICC processing times.
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    )
}
