import React from 'react'
import "./Footer.scss"

export default function Footer() {
  return (
    <>
            <div className='container' style={{ marginTop: "5rem" }}>
                <ul className='contactList'>
                    <li>
                        <a className='contactInfo' target='_blank' rel='noreferrer' href='https://maps.google.com/?q=895 Don Mills Road, Toronto, ON M3C 1W3'>
                            Two Morneau Shepell Centre, 895 Don Mills Road, Suite 900, Toronto, Ontario,
                            M3C 1W3, Canada </a>
                    </li>
                    <li>
                        <a className='contactInfo' target='_blank' rel='noreferrer' href='mailto:info@redradius.ca'>info@redradius.ca</a>
                    </li>
                    <li>
                        <a className='contactInfo' href='tel:+16473095646'>+1 (647) 521-7331</a>
                    </li>

                </ul>
            </div>
            <div className='container footerSec'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-6'>
                        <span id="copyRight">Copyright &#169;2024 RedRadius Immigration Consultancy Inc. All Rights Reserved.</span>
                    </div>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-6'>
                        <button className='btn btnFooter' type='button'>Terms of Use</button>
                        <button id='btnPrivacy' className='btn btnFooter ' type='button'>Privacy Policy</button>
                        <span id="countryLocation">Canada</span>
                    </div>
                </div>
            </div >
        </>
  )
}
