import React from 'react'
import "./Immigration.scss"
import ServiceCard from '../ServiceCard/ServiceCard'
import ONIP from "../../img/ONIP.jpeg";
import FSW from "../../img/FSW.jpeg";
import CEC from "../../img/CEC.jpeg";

export default function Immigration({children, refCEC, refFSW, refONIP}) {
  return (
    <div className='container'>
        <div className='section'>
            <div className='text-center'>
                <span className='section-tag-title'>Immigration</span>
                <p className='section-description txtMain'>Canada’s immigration pathways are tailored for skilled professionals seeking opportunities in its thriving industries. These strategic programs are crafted to align your skills with the demands of Canada's business landscape, paving the way for your success and growth in this dynamic economic hub</p>
            </div>
            <div className='row'>
                <div className="col-12 col-md-12 col-lg-4 col-xl-4 mt-5" ref={refCEC}>
                    <ServiceCard serviceCode="CEC" fontColor="" serviceSection="Immigration" serviceTitle="Canadian Experience Class" picture={CEC} serviceTxt="A program managed through Express Entry, designed for highly skilled foreign nationals with valuable Canadian work experience. This tailored stream recognizes and rewards your professional expertise gained through your Canadian work endeavors.">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Basic Eligibility
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Work Experience</h4>
                                            <p className="txtMain">At least one year of relevant work experience in Canada over the last three years</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Language ability</h4>
                                            <p className="txtMain">Valid English or French language test is required during the application process </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Education</h4>
                                            <p className="txtMain">Not required to apply for this program, but it helps improve your rank in the pool</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
                <div className="col-12 col-md-12 col-lg-4 col-xl-4 mt-5" ref={refFSW}>
                    <ServiceCard serviceCode="FSW" fontColor="#333336" serviceSection="Immigration" serviceTitle="Federal Skilled Worker" picture={FSW} serviceTxt="A program under Express Entry, crafted for skilled foreign workers with international experience. This avenue acknowledges and values your professional capabilities, offering a pathway to Canadian permanent residency"
                    >
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Basic Eligibility
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Work Experience</h4>
                                            <p className="txtMain">At least one year of relevant work experience obtained in the last 10 years</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Language ability</h4>
                                            <p className="txtMain">Valid English or French language test is required during the application process </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Education</h4>
                                            <p className="txtMain">A diploma or degree equivalent to a Canadian secondary or post-secondary school</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
                <div className="col-12 col-md-12 col-lg-4 col-xl-4 mt-5" ref={refONIP}>
                    <ServiceCard serviceCode="OINP" serviceSection="Immigration" serviceTitle="Ontario Nominee Program" picture={ONIP} serviceTxt="The province of Ontario's immigration program works in partnership with the Government of Canada to provide a pathway for foreign workers, international students, and other skilled individuals to obtain the nomination. Successful nominees can apply for permanent residence through the federal government of Canada.">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Ontario Nominee Program Streams
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Masters Graduate Stream</h4>
                                            <p className="txtMain">A pathway to permanent residency and employment in Ontario for international graduates holding a master's degree from the province of Ontario without job offer through this unique program</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">PhD Graduate Stream</h4>
                                            <p className="txtMain">An opportunity for international graduates with a Ph.D. from an Ontario University to obtain permanent residency and employment in Ontario, all without a job offer, through this program stream</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Foreign Worker Stream</h4>
                                            <p className="txtMain">This stream opens doors for individuals with a job offer in a skilled occupation, providing them with the chance to apply for permanent residency and employment in Ontario</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">International Student Stream</h4>
                                            <p className="txtMain">This program offers a unique opportunity for international students holding a job offer in a skilled occupation to apply for permanent residency and pursue a fulfilling career in Ontario</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">In-Demand Skills Stream</h4>
                                            <p className="txtMain">This stream gives foreign workers holding a job offer in specific in-demand occupations like construction the opportunity to live and work in the provice of Ontario permanently</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>

                    </ServiceCard>

                </div>
            </div>
        </div>
    </div>
  )
}
