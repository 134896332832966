import React from 'react'
import "./Booking.scss";

export default function Booking() {
  return (
    <div className='container mt-5 mb-5' >
            <div className='card booking cardEffect'>
                <div className='text-center'>
                    <p id="bookingTitle" className='txtBooking'>Navigating your way to Canada</p>
                    <p className='bookingParOne txtMain txtBooking bookingPar'>Book your 1-hour consultation meeting with a Licensed Canadian Immigration Consultant and begin your immigration journey</p>
                    <p className='txtMain txtBooking bookingPar'>Navigating Canada's complex immigration process for business, visiting, studying, or permanent relocation requires expertise. At RedRadius, we specialize in providing customized legal services for various immigration programs. We are committed to delivering exceptional services that align with your unique circumstances. Explore your possibilities by contacting us</p>
                    <a id="btnBookNow" aria-label="Book Consultation" className='btn' type='button' href='https://calendly.com/redradius/consultation-meeting' target="_blank" rel="noreferrer">Book 1-Hour Consultation Meeting Now</a>
                </div>
            </div>
        </div>
  )
}
