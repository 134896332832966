import React from 'react'
import "./Sponsorship.scss"
import ServiceCard from '../ServiceCard/ServiceCard'
import RS from "../../img/RS.jpeg";
import SS from "../../img/SS.jpeg"
import PS from "../../img/PS.jpeg";



export default function Sponsorship({refSS, refPS, refRS}) {
  return (
    <div className='container'>
        <div className='section'>
            <div className='text-center'>
                <span className='section-tag-title'>Sponsorship</span>
                <p className='section-description txtMain'>Canada extends sponsorship avenues created to reunite families, provide refuge, and offer support. These programs are designed to align with your desire for family reunification and humanitarian assistance, embodying the essence of Canada's dedication to nurturing robust and compassionate connections</p>
            </div>
            <div className='row'>
                <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-5" ref={refSS}>
                    <ServiceCard serviceCode="SS" fontColor="#AE2A39" serviceSection="Sponsorship" serviceTitle="Spousal Sponsorship" picture={SS} serviceTxt="A program allowing Canadian citizens or permanent residents to sponsor their spouse or partner for immigration, fostering family unity and serving as a pathway to facilitate the immigration process for beloved family members, ensuring their reunification in Canada">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Basic Eligibility
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Sponsor Status</h4>
                                            <p className="txtMain">A permanent resident living in Canada and Canadian citizen planning to reside in Canada</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Relationship Status</h4>
                                            <p className="txtMain">Genuine marriage or common-law or conjugal partnership recognized by Canada's immigration</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Financial Status</h4>
                                            <p className="txtMain">Ability of the sponsoring spouse to support their spouse or partner and any accompanying dependents upon their arrival in Canada</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Financial Agreement</h4>
                                            <p className="txtMain">A commitment to support the sponsored spouse or partner for three years even in cases of separation or divorce.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type="button" href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
            </div>
            <div className='row'>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-5" ref={refPS}>
                    <ServiceCard serviceCode="PS" fontColor="#333336" serviceSection="Sponsorship" serviceTitle="Parents Sponsorship" picture={PS} serviceTxt="An invitation-based program enabling Canadian citizens or permanent residents to sponsor their parents or grandparents for immigration. This program prioritizes family reunification, enabling loved ones to join and live with their families in Canada">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Eligible Groups
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Sponsor Status</h4>
                                            <p className="txtMain">A Canadian citizen or a permanent resident residing in Canada</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Financial Status</h4>
                                            <p className="txtMain">Ability to meet financial requirements to demonstrate the ability to support parents or grandparents</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Financial Agreement</h4>
                                            <p className="txtMain">A commitment to financially support the sponsored parent or grandparent upon their arrival in Canada</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-5" ref={refRS}>
                    <ServiceCard serviceCode="RS" fontColor="#333336" serviceSection="Sponsorship" serviceTitle="Refugee Sponsorship" picture={RS} serviceTxt="Private Sponsorship of Refugees allows compassionate private groups to influence the lives of eligible refugees abroad. As the private sponsor, your support covers start-up costs, basic monthly necessities, and a supportive social and emotional environment for those seeking refuge in Canada, usually for one year."
                    >
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Eligible Groups
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Sponsorship Agreement Holders</h4>
                                            <p className="txtMain">Organizations that have signed an agreement with the Government of Canada to help refugees resettle in Canada</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Groups of Five</h4>
                                            <p className="txtMain">Five or more Canadian citizens or permanent residents who sponsor refugees to settle in their communities</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Community Sponsors</h4>
                                            <p className="txtMain"> Non-governmental entities such as organizations, associations, or corporations sponsoring refugees to settle in Canada</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
            </div>
        </div>
    </div>
  )
}
