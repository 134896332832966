import React from 'react';
import "./TemporaryResidence.scss";
import ServiceCard from '../ServiceCard/ServiceCard';
import SV from "../../img/SV.jpeg";
import SP from "../../img/SP.jpeg";
import WP from "../../img/WP.jpeg"



export default function TemporaryResidence({ refSV, refWP, refSP }) {
  return (
    <div className='container'>
        <div className='section'>
            <div className='text-center'>
                <span className='section-tag-title'>Temporary Residence</span>
                <p className='section-description txtMain'>Canada offers programs designed for professionals seeking career opportunities, individuals pursuing educational goals, and families planning extended stays. These temporary programs provide enriching experiences in various aspects of Canadian environments, contributing to an immersive and rewarding journey</p>
            </div>

            <div className='row'>
                <div className="col-12 col-md-12 col-lg-4 col-xl-4 mt-5" ref={refWP}>
                    <ServiceCard serviceCode="WP" serviceSection="Temporary Residence" serviceTitle="Work Permit" picture={WP} serviceTxt="A program for foreign nationals to legally work in Canada, this pathway offers opportunities for skilled individuals to gain valuable work experience in Canada, contributing to personal and professional growth and opening doors for other programs that may be eligible for permanent residency. There are multiple work permit programs, each with specific eligibility criteria tailored to the application type you're pursuing">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Work Permit Programs
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Open Work Permit</h4>
                                            <p className="txtMain">A path for foreign nationals to work with any Canadian employer across diverse occupations, eliminating the need for their employers to obtain LMIA. This path extends to programs like spouse and post-graduate work permits</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Employer-Specific</h4>
                                            <p className="txtMain">In this program, foreign nationals typically need a job offer from a Canadian employer, depending on their occupation the potential Canadian employer may need to apply for LMIA </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">French Mobility</h4>
                                            <p className="txtMain">A pathway for individuals showcasing French proficiency with a job offer from a Canadian employer. This option is not restricted to any province, except for Quebec, and exempts employers from the LMIA requirement</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Intra-Company Transfer</h4>
                                            <p className="txtMain">An avenue for foreign nationals employed in international enterprises to seamlessly transfer to a Canadian branch. In this program, the employer is exempt from the LMIA requirement</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
                <div className="col-12 col-md-12 col-lg-4 col-xl-4 mt-5" ref={refSP}>
                    <ServiceCard serviceCode="SP" serviceSection="Temporary Residence" serviceTitle="Study Permit" picture={SP} serviceTxt="An opportunity for international students to pursue education in Canada. This program is designed to support and empower individuals seeking academic enrichment and cultural exposure in a world-class educational environment">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Basic Eligibility
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Acceptance Letter</h4>
                                            <p className="txtMain">An acceptance letter from a designated learning institution (DLI) in Canada for programs longer than 6 months leading to a certificate diploma or degre</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Financial Support</h4>
                                            <p className="txtMain">A proof of ability to support yourself financially during your studies in Canada this includes accommodation, transportation, living expenses, and tuition</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
                <div className="col-12 col-md-12 col-lg-4 col-xl-4 mt-5" ref={refSV}>
                    <ServiceCard serviceCode="SV" serviceSection="Temporary Residence" serviceTitle="Super Visa" picture={SV} serviceTxt="A program enabling parents and grandparents of Canadian citizens or permanent residents to visit Canada for extended periods. This avenue is designed to reunite families and allow loved ones to spend time together">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Basic Eligibility
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Invitation</h4>
                                            <p className="txtMain">A letter of invitation from the child or grandchild in Canada</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Financial Status</h4>
                                            <p className="txtMain">The income of the invitee meets the minimum necessary annual threshold</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Invitee’s statement</h4>
                                            <p className="txtMain">A written statement expressing intent to support their parent or grandparent</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Insurance</h4>
                                            <p className="txtMain">A valid Canadian medical insurance coverage for at least one year</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
            {/* </div> end of row */}
            </div>
        </div>
    </div>
  )
}
