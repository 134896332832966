import './App.css';
import React, {useRef} from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Immigration from './Components/Immigration/Immigration';
import Sponsorship from './Components/Sponsorship/Sponsorship';
import TemporaryResidence from './Components/TemporaryResidence/TemporaryResidence';
import Citizenship from './Components/Citizenship/Citizenship';
import FAQ from './Components/FAQ/FAQ';
import Booking from './Components/Booking/Booking';
import Footer from './Components/Footer/Footer';
import Wrapper from './Components/Wrapper';
import Navbar from './Components/Navbar/Navbar';

function App() {

  const HomeRef = useRef()
  // const AboutRef = useRef()
  const CECRef = useRef()
  const FSWRef = useRef()
  const ONIPRef = useRef()
  const PSRef = useRef()
  const SSRef = useRef()
  const RSRef = useRef()
  const WPRef = useRef()
  const SPRef = useRef()
  const SVRef = useRef()
  const CitizenshipRef = useRef()


  function moveToHome() {
    window.scrollTo({
      top: HomeRef.current.offsetTop - 56 - 2
    })
  }

  // function moveToAbout() {
  //   // AboutRef.current.scrollIntoView()
  //   window.scrollTo({
  //     top: AboutRef.current.offsetTop - 56 - 2
  //   })

  // }
  function moveToCEC() {
    // CECRef.current.scrollIntoView()
    window.scrollTo({
      top: CECRef.current.offsetTop - 56 - 2
    })
  }
  function moveToFSW() {
    // FSWRef.current.scrollIntoView()
    window.scrollTo({
      top: FSWRef.current.offsetTop - 56 - 2
    })
  }
  function moveToONIP() {
    // ONIPRef.current.scrollIntoView()
    window.scrollTo({
      top: ONIPRef.current.offsetTop - 56 - 2
    })
  }
  function moveToPS() {
    // PSRef.current.scrollIntoView()
    window.scrollTo({
      top: PSRef.current.offsetTop - 56 - 2
    })
  }
  function moveToSS() {
    // SSRef.current.scrollIntoView()
    window.scrollTo({
      top: SSRef.current.offsetTop - 56 - 2
    })
  }
  function moveToRS() {
    // RSRef.current.scrollIntoView()
    window.scrollTo({
      top: RSRef.current.offsetTop - 56 - 2
    })
  }
  function moveToWorkPermit() {
    // WPRef.current.scrollIntoView()
    window.scrollTo({
      top: WPRef.current.offsetTop - 56 - 2
    })
  }
  function MoveToStudyPermit() {
    // SPRef.current.scrollIntoView()
    window.scrollTo({
      top: SPRef.current.offsetTop - 56 - 2
    })
  }
  function moveToSuperVisa() {
    // SVRef.current.scrollIntoView()
    window.scrollTo({
      top: SVRef.current.offsetTop - 56 - 2
    })
  }
  function moveToCitizenship() {
    // CitizenshipRef.current.scrollIntoView()
    window.scrollTo({
      top: CitizenshipRef.current.offsetTop - 56 - 2
    })
  }

  return (
    <div>
      <Router>
        <Wrapper>
          <Navbar moveToHome={moveToHome}  moveToCEC={moveToCEC} moveToFSW={moveToFSW}
            moveToONIP={moveToONIP} moveToPS={moveToPS} moveToSS={moveToSS} moveToRS={moveToRS}
            moveToWorkPermit={moveToWorkPermit} MoveToStudyPermit={MoveToStudyPermit}
            moveToSuperVisa={moveToSuperVisa} moveToCitizenship={moveToCitizenship} />
          <Home refName={HomeRef} />
          <About/>
          <Immigration refCEC={CECRef} refFSW={FSWRef} refONIP={ONIPRef} />
          <Sponsorship refSS={SSRef} refPS={PSRef} refRS={RSRef}/>
          <TemporaryResidence refSV={SVRef} refSP={SPRef} refWP={WPRef}/>
          <Citizenship refName={CitizenshipRef} />
          <FAQ/>
          <Booking/>
        </Wrapper>
        <Footer/>
      </Router> 
    </div>
  );
}

export default App;
