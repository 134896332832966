// import React, { useState } from 'react';
import React from 'react';
import "./ServiceCard.scss";


export default function ServiceCard({ serviceCode, serviceSection, serviceTitle, serviceTxt, picture, children, fontColor }) {
    // const [backgroundBlur, setBackgroundBlur] = useState("");

    // remove the commented section to obtain blurred background when Modal is expanded
    // const showBlur = () => {
    //     setBackgroundBlur("overlay")
    // }
    // const hideBlur = () => {
    //     setBackgroundBlur("");
    // }


    return (
        <>
            {/* <div className={`${backgroundBlur}`}  ></div> */}
            {/* <div className='card cardEffect cardClick' data-bs-toggle="modal" data-bs-target={`#${serviceCode}`} onClick={showBlur}> */}

            <div className='card cardEffect cardClick' data-bs-toggle="modal" data-bs-target={`#${serviceCode}`}>
                <div className='cardService' style={{ backgroundImage: `url(${picture})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                    <h2 className='servTitle' style={{ color: `${fontColor}` }}>
                        {serviceTitle}
                    </h2>
                    {/* <button className='btnCardMore' type='button' data-bs-toggle="modal" data-bs-target={`#${serviceCode}`} onClick={showBlur}  ><i class="fa-solid fa-circle-plus fa-2x"></i></button> */}

                    <button className='btnCardMore' type='button' data-bs-toggle="modal" data-bs-target={`#${serviceCode}`}  ><i class="fa-solid fa-circle-plus fa-2x"></i></button>
                </div>
            </div>
            <div className="modal fade modal-xl" id={`${serviceCode}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"   >
                <div class="modal-dialog ">
                    <div class="modal-content">
                        <div class="modal-header">
                            {/* <button type="button" id="btnCloseModal" className="btnModalClose" data-bs-dismiss="modal" aria-label="Close" onClick={hideBlur}><i class="fa-solid fa-circle-xmark fa-2x"></i></button> */}

                            <button type="button" id="btnCloseModal" className="btnModalClose" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-circle-xmark fa-2x"></i></button>
                        </div>
                        <div class="modal-body">
                            <div className='containe text-center'>
                                <span className='modalTitle section-tag-title'>{serviceSection}</span>
                                <h2 className='modalSubTitle'>
                                    {serviceTitle}
                                </h2>
                                <div className='card modalTxtCard'>
                                    <div className='txtMain mb-5 modalTxt'>
                                        {serviceTxt}

                                    </div>
                                </div>
                                <div className='container'>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
