
import React, {useState} from 'react'
import "./Navbar.scss"
import Logo from "../../img/Logo.png"



export default function Navbar({ moveToHome, moveToCEC, moveToFSW, moveToONIP, moveToPS, moveToSS, moveToRS, moveToWorkPermit, MoveToStudyPermit, moveToSuperVisa, moveToCitizenship }) {


    const [navbarOpen, setNavbarOpen] = useState(false);
 
 
    const toggleNavbar = () => {
        setNavbarOpen(!navbarOpen)
    }
 
 
    const closeNavbar = () => {
        if (navbarOpen) {
            setNavbarOpen(false)
 
 
        }
    }
 
 
    const [navbarBlur, setNavbarBlur] = useState(false);
 
 
    const changeNavbarBackground = () => {
        if (window.scrollY > 1) {
            setNavbarBlur(true)
        }
        else {
            setNavbarBlur(false)
        }
    }
 
 
    window.addEventListener('scroll', changeNavbarBackground)
 
    return (
        <>
            <nav className={navbarBlur ? "navbar navbar-expand-lg navbar-light navbar-section blurred" : "navbar navbar-expand-lg navbar-light navbar-section"}>
                <div className="container-fluid">
                    <a id="logoHome" className="navbar-brand" href="#home" onClick={() => { moveToHome(); closeNavbar() }}><img id="logo" src={Logo} alt="RedRadius Logo made of double R letter" /></a>
                    <button onClick={toggleNavbar} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded={navbarOpen ? 'true' : 'false'} aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse justify-content-center ${navbarOpen ? 'show' : ''}`} id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            {/* <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#about" onClick={() => { moveToAbout(); closeNavbar() }}>About</a>
                            </li> */}
                            <li className="nav-item dropdown">
                                <a className="nav-link " href="#dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Immigration
                                </a>
                                <ul className="dropdown-menu ">
                                    <div className='dropdown-menu-container'>
                                        <li><a className="dropdown-item" href="#CEC" onClick={() => { moveToCEC(); closeNavbar() }}>Canadian Experience Class</a></li>
                                        <li><a className="dropdown-item" href="#FSK" onClick={() => { moveToFSW(); closeNavbar() }}>Federal Skilled Worker</a></li>
                                        <li><a className="dropdown-item" href="#ONIP" onClick={() => { moveToONIP(); closeNavbar() }}>Ontario Nominee Program</a></li>
                                    </div>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="#dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Sponsorship
                                </a>
                                <ul className="dropdown-menu ">
                                    <div className='dropdown-menu-container'>
                                        <li><a className="dropdown-item" href="#PS" onClick={() => { moveToPS(); closeNavbar() }}>Parents Sponsorship</a></li>
                                        <li><a className="dropdown-item" href="#SP" onClick={() => { moveToSS(); closeNavbar() }}>Spousal Sponsorship</a></li>
                                        <li><a className="dropdown-item" href="#RS" onClick={() => { moveToRS(); closeNavbar() }}>Refugee Sponsorship</a></li>
                                    </div>
                                </ul>
                            </li>
 
 
                            <li className="nav-item">
                                <a className="nav-link" href="#WorkPermit" onClick={() => { moveToWorkPermit(); closeNavbar() }}>Work Permit</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#StudyPermit" onClick={() => { MoveToStudyPermit(); closeNavbar() }}>Study Permit</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#SuperVisa" onClick={() => { moveToSuperVisa(); closeNavbar() }}>Super Visa</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#Citizenship" onClick={() => { moveToCitizenship(); closeNavbar() }}>Citizenship</a>
                            </li>
                        </ul>
                    </div>
                  
                    {/* <a id="btnConsulting" type="button" className="btn" href="https://calendly.com/redradius/free-15-minutes-introductory-call" target="_blank" rel="noreferrer">Book A Free Call </a> */}
                  
                </div>
            </nav>
       
        </>)
 }
 