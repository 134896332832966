import React from 'react'
import './Home.scss'
import Logo from '../../img/Logo.png'

export default function Home({refName}) {
  return ( 
   // <div className='container'>
   <div className='home-container' ref={refName}>
        <div className='background'>
            <div className='circle circle1'></div>
            <div className='circle circle2'></div>
            <div className='circle circle3'></div>
            <div className='circle circle4'></div>
            <div className='circle circle5'></div>
        </div>
        <div id="home-section">
            <div className='row'>
                <div className='col'>
                    <h1 id="main-title">RedRadius Immigration</h1>
                    <h2 id="main-sub-title">Your Trusted Partner in Navigating Your Pathway to Canada</h2>
                    <a id="" type="button" className="btn home-btn" href='https://calendly.com/redradius/consultation-meeting' target='_blank' rel='noreferrer'>Book A Consultation</a>
                    <button id="btnMore" className='btn home-btn' type='button' data-bs-toggle="modal" data-bs-target="#exampleModal">Learn More</button>
                </div>
            </div>
        </div>

   {/* Modal starts here  */}
   <div class="modal fade modal-xl " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog ">
            <div id="messageModal" class="modal-content">
                <div class="modal-header">
                    <button type="button" className="btnModalClose" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-circle-xmark fa-2x"></i></button>
                </div>
                <div class="modal-body">
                    <div id="message">
                        <div id='messageTxtCard' className='card'>
                            <p className='txtMain founderMessage '>
                                Faisal Nour is a Regulated Canadian Immigration Consultant RCIC, registered and licensed member in good standing of the College of Immigration and Citizenship Consultants. He holds a diploma in Immigration Law from the Canadian Society of Immigration Consultants and a Master's in Information Technology and Computing from York University.
                            </p>
                            <p className='txtMain founderMessage'>
                                As the founder of RedRadius Immigration Consultancy and an immigrant to Canada himself, he is committed to being your trusted partner in navigating the complex immigration process. His mission is to deliver an exceptional and supportive experience to our clients in keeping with constantly evolving immigration policies and by harnessing technology to offer a seamless immigration process. Although based in Toronto, his services are primarily digital, ensuring that anyone from anywhere can benefit from his expertise.
                            </p>
                        </div>
                        <p className='txtMain RCIC'>
                            Faisal Nour - Licensed Canadian Immigration Consultant
                        </p>
                        <p className='txtMain RCIC'>
                            RCIC # R713314
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}
