import React from 'react'
import "./About.scss"

export default function About() {
  return (
    <div className='container'>
        <div id='about-section'>
            <h2 id='about-title'>
                Technology-Driven, Streamlined Immigration Services for Your Journey
            </h2>
            <p id='about-body' className='txtMain'>
                We are dedicated to delivering excellence in immigration services. Our commitment is to provide innovative, streamlined solutions across various immigration programs by leveraging technology to assist you wherever you are.
            </p>
        </div>
    </div>
  )
}
