import React from 'react'
import "./Citizenship.scss"
import ServiceCard from '../ServiceCard/ServiceCard'
import Citizen from "../../img/Citizenship.jpeg";

export default function Citizenship({refName}) {
  return (
    <div className='container' ref={refName}>
        <div className='section'>
            <div className='text-center'>
                <span className='section-tag-title'>Citizenship</span>
                <p className='section-description txtMain'>Canadian citizenship unlocks a world of privileges for permanent residents, celebrating the rights and benefits of belonging. It recognizes and embraces individuals as integral members of the community, inviting them to fully participate and contribute to the dynamic and thriving Canadian society</p>
            </div>
            <div className='row'>
                <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-5">
                    <ServiceCard serviceCode="CC" serviceSection="Canadian Citizenship" serviceTitle="Canadian Citizenship" picture={Citizen} serviceTxt="Permanent residents who lived in Canada for three years may be eligible to apply for Canadian citizenship. This transformative step celebrates the rights and privileges of belonging, acknowledging, and embracing individuals as full-fledged members of the Canadian community">
                        <>
                            <div className="additionServInfo">
                                <h3 className="additionServSubTitle">
                                    Basic Eligibility
                                </h3>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Residency obligation</h4>
                                            <p className="txtMain">Physically present in Canada for at least 3 years within the five-year period, before applying</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Tax Filing</h4>
                                            <p className="txtMain"> Compliance with Canadian tax laws for at least 3 years within the five-year period, if applicable</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="card reqCard">
                                            <h4 className="servSubSec">Language Ability</h4>
                                            <p className="txtMain">Proof of language proficiency in English or French for applicants between the ages of 18 and 54</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookingSec text-center">
                                    <a className="bookingLink btn" type='button' href="https://calendly.com/redradius/consultation-meeting" target="_blank" rel="noreferrer">Book a consultation meeting now</a>
                                </div>
                            </div>
                        </>
                    </ServiceCard>
                </div>
                {/* end of row  */}
            </div>     
        </div>
    </div>
  )
}
